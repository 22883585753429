.church-promo {
    min-height: 600px;
    display: flex;
    justify-content: center;
    align-items:center;
    text-align: center;
    &-title {
        font-weight: 600;
        font-size: 70px;
        line-height: 90px;
        text-transform: capitalize;
        color: #FFFFFF;
    }

}

@media screen and (max-width:768px) { 
    .church-promo{
        &-title {
            font-size: 50px;
        }
    }
    
}

@media screen and (max-width: 576px) {
    .church-promo {
        min-height: 500px;
        max-height: 500px;
        &-title {
            font-size: 30px;
            line-height: 50px;
        }
    }
    
}