@import './mixins.scss';
@import './variables.scss';
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto';
}
html {
    scroll-behavior: smooth;
}
#myBtn {
    display: none; /* Hidden by default */
    position: fixed; /* Fixed/sticky position */
    bottom: 20px; /* Place the button at the bottom of the page */
    right: 30px; /* Place the button 30px from the right */
    z-index: 99; /* Make sure it does not overlap */
    border: none; /* Remove borders */
    outline: none; /* Remove outline */
    background: linear-gradient(96.98deg, #FF3A7F 0%, #FF5F45 100%); /* Set a background color */
    color: white; /* Text color */
    cursor: pointer; /* Add a mouse pointer on hover */
    padding: 10px; /* Some padding */
    -webkit-box-shadow: 2px 3px 15px -4px #000000; 
    box-shadow: 2px 3px 15px -4px #000000;
    border-radius: 10px; /* Rounded corners */
    font-size: 18px; /* Increase font size */
    i {
        font-size: 30px;
    }
}
.container {
    max-width: 1170px;
    margin: 0 auto;
}



